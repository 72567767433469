import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  position: relative;
  border: 1px solid ${$.color.gray};
  max-width: 298px;
  width: 100%;
  padding-top: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
`;

const HoveringTitle = styled.div`
  position: absolute;
  top: -8px;
  left: 10px;
  padding: 0 10px;
  color: ${$.color.gray};
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
  background-color: ${$.color.white};
`;

const TimeslotButton = styled.button`
  background-color: transparent;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 20px;
  border: 2px solid ${$.color.gray};
  padding: 10px;
  border-radius: 10px;
  max-width: 260px;
  color: ${$.color.gray};

  &.selected {
    border: 2px solid ${$.color.green};
    color: ${$.color.green};
  }

  &:hover {
    cursor: pointer;
  }
`;

const Timeslot = ({ timeslots, chosenSlot, setChosenSlot }) => (
  <Container>
    <HoveringTitle>Timeslot</HoveringTitle>
    {timeslots.map(({ id, slot }) => (
      <TimeslotButton
        key={`${id}_slot`}
        className={id === chosenSlot ? 'selected' : ''}
        onClick={() => {
          setChosenSlot(id);
        }}
      >
        {slot}
      </TimeslotButton>
    ))}
  </Container>
);

Timeslot.defaultProps = {
  timeslots: [],
  chosenSlot: '',
  setChosenSlot: () => {},
};

Timeslot.propTypes = {
  timeslots: PropTypes.oneOfType([PropTypes.array]),
  setChosenSlot: PropTypes.func,
  chosenSlot: PropTypes.string,
};

export default Timeslot;

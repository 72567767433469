import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../../../styles/global';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 298px;
  width: 100%;
  border: 1px solid ${$.color.gray};
  padding: 30px 0 20px 0;
  border-radius: 10px;
  position: relative;
  margin-bottom: 30px;

  & > button {
    width: calc(49% - 20px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 2px solid ${$.color.gray};
    padding: 10px 5px;
    border-radius: 10px;
    color: ${$.color.gray};

    &:hover {
      cursor: pointer;
    }

    &.selected {
      color: ${$.color.green};
      border: 2px solid ${$.color.green};
      & > div {
        background-color: ${$.color.green};
      }
    }

    & > div {
      border-radius: 99px;
      width: 8px;
      height: 8px;
      margin-right: 5px;
      background-color: ${$.color.gray};
    }

    & > span {
      display: block;
      width: calc(100% - 10px - 10px);
    }
  }
`;

const HoveringTitle = styled.div`
  position: absolute;
  top: -8px;
  left: 10px;
  padding: 0 10px;
  color: ${$.color.gray};
  letter-spacing: 1px;
  font-size: 15px;
  text-transform: uppercase;
  background-color: ${$.color.white};
`;

const DropdownStatus = styled.select`
  width: calc(100% - 10px);
  border: 1px solid ${$.color.gray};
  border-radius: 5px;
  font-size: 17px;
  padding: 5px;
  max-width: 260px;
  outline: none;
  color: ${$.color.black2};
`;

const SessionType = ({ classType, setClassType }) => (
  <Container>
    <HoveringTitle>Session Type</HoveringTitle>
    <DropdownStatus
      defaultValue={classType}
      onChange={(e) => {
        setClassType(e.target.value);
      }}
    >
      <option value="">--Choose your class--</option>
      {/* <option value="online">Online</option> */}
      <option value="offline">Offline</option>
    </DropdownStatus>
  </Container>
);

SessionType.defaultProps = {
  classType: '',
  setClassType: () => {},
};

SessionType.propTypes = {
  classType: PropTypes.string,
  setClassType: PropTypes.func,
};

export default SessionType;

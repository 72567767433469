import { useEffect, useState } from 'react';

import Utils from '../utils';
import Seo from '../components/Seo';
import CheckInForm from '../components/student/CheckInForm';
import SessionList from '../components/instructor/SessionList';

const IndexPage = () => {
  const [group, setGroup] = useState(Utils.STUDENTS_GROUP);

  useEffect(() => {
    Utils.getGroupName().then((res) => {
      setGroup(res);
    });
  }, []);

  switch (group) {
    case Utils.STUDENTS_GROUP:
      return (
        <>
          <Seo title="SGCC IMDA Attendance | Home" />
          <CheckInForm />
        </>
      );
    case Utils.ADMIN_GROUP:
    case Utils.INSTRUCTORS_GROUP:
      return (
        <>
          <Seo title="SGCC IMDA Attendance | Home" />
          <SessionList />
        </>
      );
    default:
      return <div />;
  }
};

export default IndexPage;

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Auth } from 'aws-amplify';

import $ from '../../../styles/global';
import { todayCheckInGQL } from '../../../apollo/queries';
import { addAttendanceGQL } from '../../../apollo/mutations';
import SleepImg from '../../../assets/images/sleep.png';
import SessionType from './SessionType';
import Timeslot from './Timeslot';
import Utils from '../../../utils';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const NoClassMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > img {
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }

  & > div {
    text-align: center;
    font-size: 18px;
    color: ${$.color.orange4};
  }
`;

const CheckInContainer = styled.div`
  ${({ disabled }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: ${disabled ? 'none' : 'initial'};
`}
`;

const CheckInDate = styled.div`
  border-radius: 10px;
  width: 200px;
  border: 1px solid ${$.color.gray};
  overflow: hidden;
  text-align: center;
  margin-bottom: 40px;

  & > div {
    font-size: 20px;
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: ${$.color.orange3};
  }

  & > span {
    display: block;
    font-size: 80px;
    padding: 30px 0;
  }
`;

const CheckInButton = styled.button`
  ${({ disabled }) => `
    max-width: 300px;
    width: 100%;
    border: none;
    background-color: ${disabled ? $.color.gray : $.color.green};
    padding: 10px 12px;
    border-radius: 10px;
    color: ${$.color.white};
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 20px;
    pointer-events: ${disabled ? 'none' : 'initial'};

    &:hover {
      cursor: pointer;
    }
  `}
`;

const CheckInStatusMessage = styled.div`
  ${({ error }) => `
  color: ${error ? $.color.red : $.color.green};
  text-align: center;
`}
`;

const CheckInForm = () => {
  const [checkIn, setCheckIn] = useState({});
  const [sessionType, setSessionType] = useState('');
  const [timeslots, setTimeslots] = useState([]);
  const [chosenSlot, setChosenSlot] = useState('');
  const [checkedIn, setCheckedIn] = useState(false);
  const [checkedInStatus, setCheckedInStatus] = useState(null);
  const [resubmitStatus, setResubmitStatus] = useState(false);
  const [disableCheckInButton, setDisableCheckInButton] = useState(false);
  const [todayCheckIn] = useLazyQuery(todayCheckInGQL, {
    onCompleted: ({ todayCheckIn: data }) => {
      setCheckIn(data);

      setTimeslots(() => {
        if (data.timeslots) {
          return data.timeslots.map((t) => {
            const list = data.timeslotList.filter(
              ({ timeslot_id: tid }) => tid === t
            )[0];

            return { id: t, slot: list.timeslot };
          });
        }

        return [];
      });

      setChosenSlot(data.timeslots ? data.timeslots[0] : '');
      setCheckedIn(data.checkedInStatus === Utils.CHECKEDIN);
      setSessionType(data.class_status || '');
      setResubmitStatus(() => {
        if (!data.verified && data.checkedInStatus === Utils.CHECKEDIN) {
          return true;
        }

        return false;
      });
    },
  });
  const [addAttendance] = useMutation(addAttendanceGQL, {
    onCompleted: ({ addAttendance: data }) => {
      if (!data.error) {
        setCheckedIn(true);
      }

      setCheckedInStatus({
        ok: !data.error,
        message: !data.error
          ? 'Saved. Thank you for checking in.'
          : 'Something went wrong. Please try again.',
      });
      setResubmitStatus(true);

      setTimeout(() => {
        setCheckedInStatus((prev) => ({
          ...prev,
          message: '',
        }));
      }, 2000);
    },
  });

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ username }) => {
      todayCheckIn({ variables: { student_id: username } });
    });
  }, []);

  useEffect(() => {
    if (sessionType === '' || timeslots.length === 0) {
      setDisableCheckInButton(true);
      return;
    }

    if (resubmitStatus) {
      setDisableCheckInButton(false);
      return;
    }

    if (checkedIn) {
      setDisableCheckInButton(true);
      return;
    }

    setDisableCheckInButton(false);
  }, [checkedIn, sessionType, timeslots]);

  return (
    <Section>
      <Container>
        {checkIn.error && (
          <NoClassMessage>
            <img src={SleepImg} alt="sleeping" />
            <div>No class scheduled for today.</div>
          </NoClassMessage>
        )}
        {checkIn.checkedInStatus && !checkIn.error && (
          <CheckInContainer>
            <CheckInDate>
              <div>{`${checkIn?.date_id?.split('_')[0]} '${checkIn?.date_id
                ?.split('_')[2]
                .substr(2, 4)}`}</div>
              <span>{checkIn?.date_id?.split('_')[1]}</span>
            </CheckInDate>
            <SessionType
              classType={sessionType}
              setClassType={setSessionType}
            />
            <Timeslot
              timeslots={timeslots}
              setChosenSlot={setChosenSlot}
              chosenSlot={chosenSlot}
            />
            <CheckInButton
              disabled={disableCheckInButton}
              onClick={() => {
                Auth.currentAuthenticatedUser().then(({ username }) => {
                  const obj = {
                    date_id: checkIn.date_id,
                    time_slot_taken: chosenSlot,
                    class_status: sessionType,
                    student_id: username,
                    verified: checkIn.verified || false,
                    instructor_id: checkIn.instructor_id || '',
                  };

                  addAttendance({ variables: { params: obj } });
                });
              }}
            >
              {resubmitStatus && 'Resubmit'}
              {!resubmitStatus && !checkedIn && 'Check In'}
              {!resubmitStatus && checkedIn && 'Checked In'}
            </CheckInButton>
            {checkedInStatus !== null && (
              <CheckInStatusMessage error={checkedInStatus.ok === false}>
                {checkedInStatus.message}
              </CheckInStatusMessage>
            )}
          </CheckInContainer>
        )}
      </Container>
    </Section>
  );
};

export default CheckInForm;

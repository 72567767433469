import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { getFutureCurrentSessionsGQL } from '../../apollo/queries';
import $ from '../../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: 50px 0;
`;

const YearContainer = styled.div`
  margin-bottom: 100px;
`;

const YearTitle = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 40px;
`;

const Month = styled.div`
  border: 1px solid ${$.color.blue5};
  position: relative;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
  margin-bottom: 30px;
`;

const MonthTitle = styled.div`
  position: absolute;
  top: -12px;
  left: 10px;
  color: ${$.color.blue5};
  font-size: 20px;
  font-weight: bold;
  padding: 0 5px;
  background-color: ${$.color.white};
`;

const DayCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const DayCard = styled(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${$.color.orange4};
  border: 2px solid ${$.color.orange4};
  padding: 8px;
  border-radius: 10px;
  width: 52px;
  transition: all 0.25s ease;

  & > div {
    color: ${$.color.white};
    text-align: center;

    &:first-child {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 1.5px;
      margin-bottom: ${$.layout().margin5}px;
      & > span {
        font-size: 15px;
      }
    }

    &:last-child {
      font-size: 14px;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${$.color.white};

    & > div {
      color: ${$.color.orange4};
    }
  }
`;

const SessionList = () => {
  const [list, setList] = useState({
    a: [],
    b: [],
    c: [],
    aMonths: [],
    bMonths: [],
    cMonths: [],
  });
  const [getFutureCurrentSessions] = useLazyQuery(getFutureCurrentSessionsGQL, {
    onCompleted: ({ getFutureCurrentSessions: data }) => {
      const year2022 = data.filter(
        ({ date_id: dateId }) => dateId.indexOf('2022') > -1
      );
      const year2023 = data.filter(
        ({ date_id: dateId }) => dateId.indexOf('2023') > -1
      );
      const year2024 = data.filter(
        ({ date_id: dateId }) => dateId.indexOf('2024') > -1
      );
      const months2022 = [];
      const months2023 = [];
      const months2024 = [];

      year2022.forEach(({ date_id: dateId }) => {
        const month = dateId.substr(0, 3);

        if (months2022.indexOf(month) === -1) {
          months2022.push(month);
        }
      });

      year2023.forEach(({ date_id: dateId }) => {
        const month = dateId.substr(0, 3);

        if (months2023.indexOf(month) === -1) {
          months2023.push(month);
        }
      });

      year2024.forEach(({ date_id: dateId }) => {
        const month = dateId.substr(0, 3);

        if (months2024.indexOf(month) === -1) {
          months2024.push(month);
        }
      });

      setList({
        a: year2022,
        b: year2023,
        c: year2024,
        aMonths: months2022,
        bMonths: months2023,
        cMonths: months2024,
      });
    },
  });
  const setDayCard = (dateId, mnth) => {
    const arr = dateId.split('_');
    const day = arr[1];
    const parsedDate = `${arr[1]} ${arr[0]} ${arr[2]}`;
    const dayOfTheWeek = new Date(parsedDate).toLocaleString('en-US', {
      timeZone: 'Asia/Hong_Kong',
      weekday: 'short',
    });
    let dayGrammar;

    switch (Number(day)) {
      case 1:
      case 21:
      case 31:
        dayGrammar = 'st';
        break;
      case 2:
      case 22:
        dayGrammar = 'nd';
        break;
      case 3:
      case 23:
        dayGrammar = 'rd';
        break;
      default:
        dayGrammar = 'th';
        break;
    }

    if (dateId.indexOf(mnth) > -1) {
      return (
        <DayCard key={dateId} to={`/verify/?date_id=${dateId}`}>
          <div>
            {day}
            <span>{dayGrammar}</span>
          </div>
          <div>{dayOfTheWeek}</div>
        </DayCard>
      );
    }

    return '';
  };

  useEffect(() => {
    getFutureCurrentSessions();
  }, []);

  return (
    <Section>
      <Container>
        {list.c.length > 0 && (
          <YearContainer>
            <YearTitle>2024</YearTitle>
            {list.cMonths.map((mnth) => (
              <Month key={`${mnth}-2024`}>
                <MonthTitle>{mnth}</MonthTitle>
                <DayCards>
                  {list.c.map(({ date_id: dateId }) =>
                    setDayCard(dateId, mnth)
                  )}
                </DayCards>
              </Month>
            ))}
          </YearContainer>
        )}
        {list.b.length > 0 && (
          <YearContainer>
            <YearTitle>2023</YearTitle>
            {list.bMonths.map((mnth) => (
              <Month key={`${mnth}-2023`}>
                <MonthTitle>{mnth}</MonthTitle>
                <DayCards>
                  {list.b.map(({ date_id: dateId }) =>
                    setDayCard(dateId, mnth)
                  )}
                </DayCards>
              </Month>
            ))}
          </YearContainer>
        )}
        {list.a.length > 0 && (
          <YearContainer>
            <YearTitle>2022</YearTitle>
            {list.aMonths.map((mnth) => (
              <Month key={`${mnth}-2022`}>
                <MonthTitle>{mnth}</MonthTitle>
                <DayCards>
                  {list.a.map(({ date_id: dateId }) =>
                    setDayCard(dateId, mnth)
                  )}
                </DayCards>
              </Month>
            ))}
          </YearContainer>
        )}
      </Container>
    </Section>
  );
};

export default SessionList;
